import React from 'react';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/homepage/home';

import './App.css';
import './i18n';
import { useTranslation } from 'react-i18next';
import CustomHeader from './components/CHeader';
import CardsList from './pages/StakePad/StakePad';

function App() {
  const {t,i18n} = useTranslation();
  const changeLanguage = (value) => {
    i18n.changeLanguage(value);
  };
  
  return (
    <>
      
      <Router>
        <CustomHeader changeLanguage={changeLanguage} i18n={i18n}/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/stakehub" element={<CardsList />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
