import React from 'react';
import { Select } from 'antd';

import { ConnectButton } from '@rainbow-me/rainbowkit';
import './../../node_modules/@rainbow-me/rainbowkit/dist/index.css';
const { Option } = Select;

const LanguageSelector = ({ i18n, changeLanguage }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <ConnectButton label="Connect" />
      <div>
        <Select
          defaultValue={i18n.language}
          style={{
            width: 120,
            borderRadius: '5px',
            border: '1px solid #ccc',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
          }}
          onChange={changeLanguage}
          dropdownStyle={{
            borderRadius: '5px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
          }}
        >
          <Option value="en">English</Option>
          <Option value="zh">中文</Option>
          {/* Add more language options as needed */}
        </Select>
      </div>
    </div>
  );
};

export default LanguageSelector;