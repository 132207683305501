// useTokenBalance.js
import { useEffect } from 'react';
import { useAccount } from 'wagmi';
import { useReadContract } from 'wagmi';
import { ethers } from 'ethers';
import erc20abi from './../abi/erc20.json'
// ERC20合约地址和ABI



const useTokenBalance = (ca) => {
  const { address } = useAccount(); // 获取当前用户地址

  // 使用useReadContract读取余额
  const { data: balanceData, refetch } = useReadContract({
    address: ca,
    abi: erc20abi,
    functionName: 'balanceOf',
    args: [address],
    enabled: !!address, // 只有在用户已连接时才启用
  });

  // 处理余额数据
  const balance = balanceData ? ethers.formatUnits(balanceData, 18) : null; // 假设代币有18位小数

  useEffect(() => {
    // 每5秒刷新一次余额
    const interval = setInterval(() => {
      refetch();
    }, 5000);

    // 清理定时器
    return () => clearInterval(interval);
  }, [refetch]);

  return { balance, refetch };
};

export default useTokenBalance;