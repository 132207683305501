
import { Layout, Row, Col} from 'antd';
import { useTranslation } from 'react-i18next';
import './home.css';
const {  Content, Footer } = Layout;

export default function Home() {
    const {t} = useTranslation();
    
    return (
        <Layout className="layout">
        <Content className="main-content">
          <div className="hero-section">
            <h1 className="main-title">MintSync</h1>
            <h2 className="sub-title">{t('subtitle')}</h2>
            <p className="description">
              {t('introduction')}
            </p>
            <p className="description1">
            {t('Int')}
</p>
          </div>

          

          {/* Section Introduction */}
          <div className="section-title">{t('explore')}</div>

          {/* TokenLaunch Section */}
          
          {/* StakeHub Section */}
          <div className="sub-section-title">{t('StakeHub.title')}</div>
          <Row gutter={[16, 16]} className="token-creation-section">
            <Col xs={24} sm={12} md={8}>
              <div className="card">
              <h2>{t('StakeHub.h1')}</h2>
              <p>{t('StakeHub.p1')}</p>
              </div>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <div className="card">
              <h2>{t('StakeHub.h2')}</h2>
              <p>{t('StakeHub.p2')}</p>
              </div>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <div className="card">
              <h2>{t('StakeHub.h3')}</h2>
              <p>{t('StakeHub.p3')}</p>
              </div>
            </Col>
          </Row>

          {/* CryptoCasino Section */}
          <div className="sub-section-title">{t('DEFI.title')}</div>
          <Row gutter={[16, 16]} className="token-creation-section">
            <Col xs={24} sm={12} md={8}>
              <div className="card">
                <h2>{t('DEFI.h1')}</h2>
                <p>{t('DEFI.p1')}</p>
              </div>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <div className="card">
                <h2>{t('DEFI.h2')}</h2>
                <p>{t('DEFI.p2')}</p>
              </div>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <div className="card">
                <h2>{t('DEFI.h3')}</h2>
                <p>{t('DEFI.p3')}</p>
              </div>
            </Col>
          </Row>
        </Content>
        <Footer className="footer-section">
          <div className="footer-content">
            <div className="footer-column">
              <img src="logo192.png" alt="logo" className="footer-logo" />
            </div>
            <div className="footer-column">
              <h4>Community</h4>
              <p>Telegram</p>
            </div>
            <div className="footer-column">
              <h4>Follow Us</h4>
              <p>Twitter</p>
            </div>

          </div>
        </Footer>
      </Layout>

    );
}