import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import {
  bsc
} from 'wagmi/chains';

import {
  injectedWallet ,
  trustWallet,
  tokenPocketWallet,
  metaMaskWallet,
  coinbaseWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';

import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import { createConfig ,http } from 'wagmi';
const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [trustWallet,tokenPocketWallet,metaMaskWallet,walletConnectWallet,],
    },{
      groupName: 'Others',
      wallets: [coinbaseWallet, injectedWallet],
    },
  ],
  { appName: 'RainbowKit App', projectId: 'YOUR_PROJECT_ID' },
);



export const config = createConfig({
  chains: [bsc],
  connectors: connectors, 
  transports: {
    [56]: http('https://bsc-dataseed1.binance.org/'),
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider>
            
            <App />
            </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
