import React, { useState } from "react";
import { Layout, Drawer, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { useNavigate ,Link} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import LanguageSelector from "./LanguageSelector";

const { Header } = Layout;

const CustomHeader = ({ changeLanguage, i18n }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [drawerVisible, setDrawerVisible] = useState(false);

  // Hook to detect screen size
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setDrawerVisible(false); // 关闭抽屉
  };

  return (
    <>
    
    <Header className="custom-header" style={{ height: '50px' }}>
      
      <div className="logo-button">
        <button className="logo-btn" onClick={() => { navigate("/") }}>
          <img src="logo192.png" alt="logo" />
        </button>
      </div>
      
      {isDesktop ? (
        <div className="menu">
          {/*<Link to="/tokenlaunch" className="transparent-button">{t('header.tokenlaunch')}</Link>*/}
          <Link to="/stakehub" className="transparent-button">{t('header.stakehub')}</Link>
          <Link to="/cryptocasino" className="transparent-button">{t('header.cryptocasino')}</Link>
          
        </div>
        
      ) : (
        <>
          <Button
            className="menu-button"
            type="primary"
            icon={<MenuOutlined />}
            onClick={toggleDrawer}
          />
          <Drawer
            title={'MintSync'}
            placement="left"
            onClose={toggleDrawer}
            visible={drawerVisible}
            bodyStyle={{ backgroundColor: '#fff' }} // Set background color to white
          >
            <div className="drawer-content">
              <Button 
                onClick={() => handleNavigation('/tokenlaunch')}
                style={{ 
                  margin: '10px 0', 
                  fontSize: '16px', 
                  color: '#000', /* Black text */
                  display: 'block', /* Make each link a block-level element */
                  padding: '10px 0', /* Add some padding */
                  backgroundColor: 'transparent', /* Ensure transparent background */
                  border: 'none', /* Remove button borders */
                  textAlign: 'left', /* Align text to left */
                  width: '100%' /* Make button full-width */
                }}
              >
                {t('header.tokenlaunch')}
              </Button>
              <Button 
                onClick={() => handleNavigation('/stakehub')}
                style={{ 
                  margin: '10px 0', 
                  fontSize: '16px', 
                  color: '#000', /* Black text */
                  display: 'block', /* Make each link a block-level element */
                  padding: '10px 0', /* Add some padding */
                  backgroundColor: 'transparent', /* Ensure transparent background */
                  border: 'none', /* Remove button borders */
                  textAlign: 'left', /* Align text to left */
                  width: '100%' /* Make button full-width */
                }}
              >
                {t('header.stakehub')}
              </Button>
              <Button 
                onClick={() => handleNavigation('/cryptocasino')}
                style={{ 
                  margin: '10px 0', 
                  fontSize: '16px', 
                  color: '#000', /* Black text */
                  display: 'block', /* Make each link a block-level element */
                  padding: '10px 0', /* Add some padding */
                  backgroundColor: 'transparent', /* Ensure transparent background */
                  border: 'none', /* Remove button borders */
                  textAlign: 'left', /* Align text to left */
                  width: '100%' /* Make button full-width */
                }}
              >
                {t('header.cryptocasino')}
              </Button>
            </div>
          </Drawer>
        </>
      )}
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <LanguageSelector i18n={i18n} changeLanguage={changeLanguage}/>
      </div>
    </Header>
    </>
  );
};

export default CustomHeader;
