import React, { useState } from "react";
import { Modal, Button, Input, Row, Col } from "antd";
import { LockOutlined } from '@ant-design/icons';
import useTokenBalance from '../hook/useTokenBalance';
import { useTranslation } from 'react-i18next';

const CustomModal = ({ visible, onClose, onEnable, ca, name }) => {
  const [pledgeAmount, setPledgeAmount] = useState(0);
  const { balance } = useTokenBalance(ca); // Fetch token balance
  const { t } = useTranslation();  // 翻译钩子
  const handleMaxClick = () => {
    setPledgeAmount(parseFloat(balance).toFixed(3)); // Format balance to 3 decimal places
  };

  const handleApproveClick = () => {
    console.log("Approved Pledge: ", pledgeAmount);
    onEnable(pledgeAmount); // Pass the formatted pledge amount
    onClose(); // Close modal
  };

  return (
    <Modal
      title={`Stake ${name}`}
      open={visible}
      onCancel={onClose}
      footer={null}
      centered
    >
      <div style={{ textAlign: "center" }}>
        <h2>{t('balance')}</h2>
        <h1 style={{ fontSize: "32px", marginBottom: "0" }}>
          {parseFloat(balance).toFixed(3)} {/* Display balance with 3 decimal places */}
        </h1>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <div>{t('Locked')}</div>
            <div style={{fontWeight:'bold'}}>0.000</div>
          </Col>
          <Col span={8}>
            <div>{t('Release')}</div>
            <div style={{fontWeight:'bold'}}>0.00000</div>
          </Col>
          <Col span={8}>
            <div>{t('Available')}</div>
            <div style={{fontWeight:'bold'}}>{parseFloat(balance).toFixed(3)}</div> {/* 3 decimal places */}
          </Col>
        </Row>
      </div>

      <div style={{ marginTop: "20px", padding: "10px", border: "1px solid #f0f0f0", borderRadius: "8px" }}>
        <Row align="middle">
          <Col span={4}>
            <LockOutlined style={{ fontSize: '24px' }} />
          </Col>
          <Col span={8}>
            <div>{name}</div>
          </Col>
          <Col span={12}>
            <div style={{ textAlign: "right" }}>
              <span>Remaining: {parseFloat(balance).toFixed(3)}</span> {/* 3 decimal places */}
              <Button size="small" onClick={handleMaxClick} style={{ marginLeft: "10px" }}>
                Max
              </Button>
            </div>
          </Col>
        </Row>
        <Input
          type="number"
          value={pledgeAmount}
          onChange={(e) => setPledgeAmount(parseFloat(e.target.value).toFixed(3))} // 3 decimal places for input value
          placeholder="Enter Stake Amount"
          style={{ marginTop: "10px" }}
        />
      </div>

      <Button
        type="primary"
        block
        style={{ marginTop: "20px", backgroundColor: "#f7e9d9", color: "#000" }}
        onClick={handleApproveClick}
      >
        Approve
      </Button>
    </Modal>
  );
};

export default CustomModal;
