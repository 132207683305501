import React, { useState } from 'react';
import { Card, Button, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomModal from '../../components/StakePanel';


import './StakePad.css'

const data = [
  { 

    tokenName: 'BIB', 
    apr: '3.25%', 
    currentStaked: '100', 
    totalStaked: '50000000000000', 
    remainingTime: '20', 
    icon: '/logo/0xe9b14e4d05eb22b47dfb8a10708be83c17bbcbbc.png',
    StakePeriod:[7,30,180],  // 质押周期
    ca:"0xe9b14e4d05eb22b47dfb8a10708be83c17bbcbbc"
  }
  // 添加其他数据项
];



const StakeCard = ({ tokenName, apr, currentStaked, totalStaked, remainingTime, icon, StakePeriod ,ca}) => {
  const { t } = useTranslation();  // 翻译钩子
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleEnable = (value) => {
    console.log('Enable clicked with value:', value);
    // 这里可以添加处理逻辑，比如提交输入值
  };

  return (
    <Card style={{ borderRadius: '12px', width: '400px', margin: '10px', padding: '10px', backgroundColor: '#2C2C2C', color: '#FFF' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
        <img src={icon} alt={tokenName} style={{ width: '70px', height: '70px', marginRight: '20px', borderRadius: '35px' }} />
        <h3 style={{ color: '#FF99CC', fontSize: '24px' }}>{tokenName}</h3>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
        <div style={{ textAlign: 'left', color: '#B0B0B0', fontSize: '16px' }}>
          <p>{t('StakeHub.apr')}:</p>
          <p>{t('StakeHub.stake')}:</p>
          <p>{t('StakeHub.tstake')}:</p>
          <p>{t('StakeHub.stakep')}:</p> {/* 质押周期 */}
          <p>{t('StakeHub.tleft')}:</p>
        </div>
        <div style={{ textAlign: 'right', color: '#FFF', fontSize: '16px' }}>
          <p>{apr}</p>
          <p>{currentStaked}</p>
          <p>{totalStaked} {tokenName}</p>
          <p>{StakePeriod.join(', ')} {t("StakeHub.d")}</p>  {/* 将质押周期以逗号分隔 */}
          <p>{remainingTime} {t("StakeHub.d")}</p>
        </div>
      </div>

      <Button onClick={showModal} type="primary" style={{ width: '100%', marginTop: '16px', backgroundColor: '#FF99CC', borderColor: '#FF99CC',fontWeight:'bold',fontSize:'20px'}}>
        {t('StakeHub.AddStake')} {/* 翻译按钮 */}
      </Button>
      <CustomModal visible={modalVisible} onClose={handleCancel} onEnable={handleEnable} ca={ca} name ={tokenName} />
    </Card>
  );
};

const CardsList = () => {
  const [page, setPage] = useState(1);
  const cardsPerPage = 12;

  const handlePageChange = (page) => {
    setPage(page);
  };

  const paginatedData = data.slice((page - 1) * cardsPerPage, page * cardsPerPage);

  return (
    <div style={{ textAlign: 'center', padding: '20px', backgroundColor: '#1C1C1C', minHeight: '100vh' }}>
      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        {paginatedData.map((item, index) => (
          <StakeCard key={index} {...item} />
        ))}
      </div>

      <Pagination
        current={page}
        total={data.length}
        pageSize={cardsPerPage}
        onChange={handlePageChange}
        style={{ marginTop: '24px', display: 'flex', justifyContent: 'center', color: '#FFF' }}
      />
    </div>
  );
};

export default CardsList;
